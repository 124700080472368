<template>
  <el-carousel trigger="click" class="el-carousel">
    <el-carousel-item v-for="item in bannerList" :key="item.img">
      <img :src="item.img" loading="lazy" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "carousel",
  props: {
    bannerList: [],
    type: Array,
  },
};
</script>

<style lang="less">
 .el-carousel {
  height: 100%;
  .el-carousel__container {
    height: 100%;
  }
  .is-active {
    button {
      background-color: #ec872b;
    }
  }
  .el-carousel__button {
    width: 40px;
    height: 3px;
    background-color: #e5e5e5;
    opacity: 1;
  }
  .el-carousel__arrow{
    display: none;
  }
  .el-carousel__indicators--horizontal {
    bottom: 30px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
