
import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
import Layout from '@/components/layout/index.vue'
Vue.use(Router)  //Vue全局使用Router

const Home = () => import('@/view/home/index.vue')
const Beauti = () => import('@/view/beauti/index.vue')
const Sanxia = () => import('@/view/sanxia/index.vue')
const Carbon = () => import('@/view/carbon/index.vue')
const Pay = () => import('@/view/pay/index.vue')
const Hardware = () => import('@/view/hardware/index.vue')
const Business = () => import('@/view/business/index.vue')
const Promote = () => import('@/view/promote/index.vue')
const Community = () => import('@/view/community/index.vue')
const Hotel = () => import('@/view/hotel/index.vue')
const Begin = () => import('@/view/begin/index.vue')
const Promotion = () => import('@/view/promotion/index.vue')
const About = () => import('@/view/about/index.vue')
const Join = () => import('@/view/join/index.vue')
const Contact = () => import('@/view/contact/index.vue')

export default new Router({
  routes: [              //配置路由，这里是个数组
    {                    //每一个链接都是一个对象
      path: '/',         //链接路径
      name: 'Layout',     //路由名称，
      component: Layout,   //对应的组件模板
      children: [
        {
          path: '/',
          name: 'Home',
          title: "首页",
          component: Home,
        },
        {
          path: '/sanxia',
          name: 'sanxia',
          title: "三夏云SasS平台",
          component: Sanxia,
        },
        {
          path: '/beauti',
          name: 'beauti',
          title: "智慧美业SasS平台",
          component: Beauti,
        },
        {
          path: '/carbon',
          name: 'carbon',
          title: "碳数据SasS平台",
          component: Carbon,
        },
        {
          path: '/pay',
          name: 'pay',
          title: "聚合支付分账平台",
          component: Pay,
        },
        {
          path: '/hardware',
          name: 'hardware',
          title: "智能硬件化IOT平台",
          component: Hardware,
        },
        {
          path: '/business',
          name: 'business',
          title: "智慧商业",
          component: Business,
        },
        {
          path: '/promote',
          name: 'promote',
          title: "乡村振兴",
          component: Promote,
        },
        {
          path: '/community',
          name: 'community',
          title: "智慧社区",
          component: Community,
        },
        {
          path: '/hotel',
          name: 'hotel',
          title: "智慧酒店",
          component: Hotel,
        },
        {
          path: '/begin',
          name: 'begin',
          title: "快速开始",
          component: Begin,
        },
        {
          path: '/promotion',
          name: 'promotion',
          title: "店家促销交流平台",
          component: Promotion,
        },
        {
          path: '/about',
          name: 'about',
          title: "关于我们",
          component: About,
        },
        {
          path: '/join',
          name: 'join',
          title: "加入我们",
          component: Join,
        },
        {
          path: '/contact',
          name: 'contact',
          title: "联系我们",
          component: Contact,
        },
      ]
    }
  ]
})