import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/index.js'
Vue.config.productionTip = false
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  Container,
  Header,
  Main,
  Footer,
  Loading
} from 'element-ui'
import carousel from "@/components/carousel/index.vue";
Vue.component('carousel', carousel)
Vue.use(Row)
Vue.use(Col)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Header)
Vue.use(Main)
Vue.use(Container)
Vue.use(Footer)
Vue.use(Loading);
//进入页面返回页面最顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
