<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app,
body {
  padding: 0;
  margin: 0;
}
div {
  box-sizing: border-box;
}
</style>
