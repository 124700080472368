<template>
  <div class="layout">
    <el-container>
      <el-header>
        <div class="header">
          <img alt="暂无图片" loading="lazy" src="https://mpkyn.oss-cn-beijing.aliyuncs.com/sanxiam/website/assets/logo.png"
            class="img" @click="logoClick" />
          <div>
            <span class="header-first" :style="{ color: isTitle == 'first' ? '#ec872b' : '' }">产品与服务
            </span>
            <div class="first">
              <div>
                <span @click="topClick('sanxia')" :style="{ color: isShow == 'sanxia' ? '#ec872b' : '' }">三夏云SaaS平台</span>
                <span @click="topClick('beauti')"
                  :style="{ color: isShow == 'beauti' ? '#ec872b' : '' }">智慧美业SaaS平台</span>
                <span @click="topClick('carbon')" :style="{ color: isShow == 'carbon' ? '#ec872b' : '' }">碳数据SaaS平台</span>
                <span @click="topClick('pay')" :style="{ color: isShow == 'pay' ? '#ec872b' : '' }">聚合支付分账平台</span>
                <!-- <span
                  @click="topClick('hardware')"
                  :style="{ color: isShow == 'hardware' ? '#ec872b' : '' }"
                  >智能硬件IOT平台</span
                > -->
              </div>
            </div>
          </div>
          <div>
            <span class="header-second" :style="{ color: isTitle == 'second' ? '#ec872b' : '' }">解决方案
            </span>
            <div class="second">
              <div>
                <span @click="topClick('business')" :style="{ color: isShow == 'business' ? '#ec872b' : '' }">智慧商业</span>
                <span @click="topClick('promote')" :style="{ color: isShow == 'promote' ? '#ec872b' : '' }">乡村振兴</span>
                <span @click="topClick('community')"
                  :style="{ color: isShow == 'community' ? '#ec872b' : '' }">智慧社区</span>
                <span @click="topClick('hotel')" :style="{ color: isShow == 'hotel' ? '#ec872b' : '' }">智慧酒店</span>
              </div>
            </div>
          </div>
          <div>
            <span class="header-threeth" :style="{ color: isTitle == 'threed' ? '#ec872b' : '' }">开发者中心
            </span>
            <div class="threeth">
              <div>
                <span @click="topClick('begin')" :style="{ color: isShow == 'begin' ? '#ec872b' : '' }">快速开始</span>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-main style="overflow-y: hidden; padding: 0">
        <div>
          <router-view></router-view>
        </div>
      </el-main>
      <el-footer>
        <div class="footer">
          <el-row class="footer1">
            <el-col :span="4">
              <ul>
                <li style="cursor: auto">产品</li>
                <li @click="topClick('sanxia')">三夏云SaaS平台</li>
                <li @click="topClick('beauti')">智慧美业SaaS平台</li>
                <li>智能硬件IOT平台</li>
                <li>企业数字化</li>
                <li @click="topClick('carbon')">碳数据SaaS平台</li>
                <li @click="topClick('pay')">聚合支付分账平台</li>
                <li>本地生活服务数字化平台</li>
                <li @click="bottomClick('promotion')">店家促销导流平台</li>
              </ul>
            </el-col>
            <el-col :span="4">
              <ul>
                <li style="cursor: auto">解决方案</li>
                <li>智慧产业园</li>
                <li>碳资产管理</li>
                <li>乡村振兴</li>
                <li>智慧政务</li>
                <li>人力资源外包服务</li>
                <li>品牌营销整合</li>
              </ul>
            </el-col>
            <el-col :span="4">
              <ul>
                <li style="cursor: auto">开发者中心</li>
                <li>开发指南</li>
              </ul>
            </el-col>
            <el-col :span="4">
              <ul>
                <li style="cursor: auto">关于我们</li>
                <li @click="bottomClick('about')">关于我们</li>
                <li @click="bottomClick('join')">加入我们</li>
                <li @click="bottomClick('contact')">联系我们</li>
              </ul>
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col :span="12">
                  <ul>
                    <li style="cursor: auto">咨询专线</li>
                    <li class="bigFont" style="cursor: auto">400-800-7020</li>
                    <li style="cursor: auto">7×24 小时服务热线</li>
                  </ul>
                </el-col>
                <el-col :span="12">
                  <ul style="text-align: right">
                    <li style="margin-top: 0; cursor: auto">
                      <img alt="暂无图片" loading="lazy" src="./ewm.png" style="margin: 0 auto; display: block" />
                    </li>
                    <li class="weixin" style="cursor: auto">三夏云微信二维码</li>
                  </ul>
                </el-col>
              </el-row>
              <ul style="text-align: left">
                <li class="block-font" style="cursor: auto">市场合作</li>
                <li style="cursor: auto;font-weight: 400;font-size: 1.06667vw;">aman_dong@sanxiam.com</li>
                <li>
                </li>
                <li class="">
                </li>
              </ul>
            </el-col>
          </el-row>
          <div class="footer">
            <el-row style="text-align: center" class="footer3">

              <el-col>
                <ul style="text-align: left;font-size: 0.93333vw;">
                  <li style="list-style: none;">
                    <img alt="暂无图片" loading="lazy" src="./beian_logo.png" style="height: 1.06667vw;width: 1.06667vw;" />
                    <span style="margin: 0.26667vw;"></span>
                    <a href="https://beian.mps.gov.cn/" target="_blank" style="">京公网安备11010602200885号</a>
                  </li>
                  <li style="list-style: none;">
                    <span style="margin: 0.8vw;"></span>
                    <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2020040279号</a>
                  </li>
                </ul>
              </el-col>
              <el-col style="font-size: 0.93333vw;">
                <ul style="text-align: left;padding-inline-start:0%;list-style: none;">
                  <li>Copyright © 2017 - 2023 Sanxiam. All Right Reserved. 三夏云 版权所有</li>
                  <li>服务主体：三夏（北京）科技有限公司</li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: "sanxia", //次级菜单
      isTitle: "first", //一级菜单
      isBottomShow: "", //底部菜单栏
    };
  },
  watch: {
    isShow: {
      handler(item1, item2) {
        let arr = ["business", "promote", "community", "hotel"];
        let arr1 = ["sanxia", "beauti", "carbon", "pay", "hardware"];
        let arr2 = ["begin"];
        if (arr.includes(item1)) {
          this.isTitle = "second";
        } else if (arr1.includes(item1)) {
          this.isTitle = "first";
        } else if (arr2.includes(item1)) {
          this.isTitle = "threed";
        } else {
          this.isTitle = "";
        }
      },
      immediate: true,
    },
    $route: {
      handler(val, oldval) {
        this.isShow = val.name;
        if (val.name == "Home") {
          this.isShow = "";
          this.isTitle = "";
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  methods: {
    topClick(item) {
      this.isShow = String(item);
      this.$router.push({ path: `/${item}` });
    },
    bottomClick(item) {
      this.isBottomShow = String(item);
      this.$router.push({ path: `/${item}` });
      this.isShow = "";
      this.isTitle = "";
    },
    logoClick() {
      this.$router.push({ path: "/" });
      this.isShow = "";
      this.isTitle = "";
    }
  },
};
</script>

<style lang="less" scoped>
.layout {
  .el-con .el-main {
    padding: 0;
  }

  .el-main {
    >div {
      width: 100%;
      // min-height: 1000px;
    }
  }

  .el-header {
    position: relative;
    z-index: 999;
    width: 100%;
    height: 80px !important;
    padding: 0;

    .header {
      padding-right: 500px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 100px;
        height: 80px;
        margin: 0 40px;
        cursor: pointer;
      }

      span {
        margin: 0 50px;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: #ec872b;
        }
      }

      .header-first,
      .header-second,
      .header-threeth {
        position: relative;
        display: block;
        height: 80px;
        line-height: 80px;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }

      .header-first:hover+.first {
        height: 100px;
      }

      .header-second:hover+.second {
        height: 100px;
      }

      .header-threeth:hover+.threeth {
        height: 100px;
      }

      .first:hover,
      .second:hover,
      .threeth:hover {
        height: 100px;
      }

      .first,
      .second,
      .threeth {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: 0;
        line-height: 100px;
        background-color: #fff;
        transition: all 0.5s;
        overflow: hidden;

        &>div {
          width: 1200px;
          height: 100%;
          margin: auto;
        }

        .span {
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 1px;
          background-color: #ebe9eb;
        }
      }
    }
  }

  .el-footer {
    width: 1200px;
    margin: auto;
    padding: 0;

    .footer {
      // padding: 0 0 50px;
      width: 1200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .footer1,
      .footer2,
      .footer3 {
        border-bottom: 1px solid #e5e5e5;
      }

      .footer1 {
        flex: 1;

        ul {
          padding: 0;
          display: flex;
          flex-direction: column;

          li {
            list-style: none;
            margin-top: 20px;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            cursor: pointer;
          }

          li:first-child {
            font-weight: 600;
            font-size: 16px;
          }

          li.block-font {
            font-weight: 600;
            font-size: 16px;
          }

          img {
            width: 170px;
            height: 170px;
          }

          .bigFont {
            font-weight: 700;
            font-size: 28px;
          }
        }
      }

      .footer2 {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        span {
          padding: 0 10px;
        }

        a {
          color: #000;
          text-decoration: none;
        }

        a:hover {
          cursor: pointer;
          color: #024b97;
        }
      }

      .footer3 {
        height: 100px;
        display: flex;
        border-bottom: none;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      .weixin {
        text-align: center;
        //margin-right: 10px;
      }
    }
  }
}
</style>
